import {customElement, query} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {loadTrackingLibrary} from '../../../firebase-analytics/local/helpers/TrackingLibraryLoaderHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {config} from '../../../../config';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {listen} from '../../../__internal/local/helpers/decorators/ListenDecoratorHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';

let maps: any;
const getMaps = async (apiKey: string) => {
    if (!maps) {
        maps = new Promise(async s => {
            let callback = `__loadGoogleMaps`;
            let win = window as any;
            win[callback] = () => {
                s(win.google.maps);
                delete win[callback];
            };

            await loadTrackingLibrary(`https://maps.googleapis.com/maps/api/js?callback=${callback}&v=weekly&key=${apiKey}`);
        });
    }

    return await maps;
};

@customElement('component-google-map')
export class ComponentGoogleMap extends BunnyElement {

    @property({type: String, notify: true})
    mapType: string;

    @property({type: Number, notify: true})
    zoom: number = 0;

    @property({type: Array, notify: true})
    center: number[] = [0, 0];

    @property({type: Boolean})
    dynamicCentering: boolean = false;

    private isAttached: boolean;

    @property({type: Object, notify: true})
    map: any;

    @property({type: Object, notify: true})
    api: any;

    @property({type: Number})
    minZoom: number = 1;

    @property({type: Number})
    maxZoom: number = 22;

    @property({type: Array})
    markers: any[];

    @property({type: Array})
    mapStyles: any[] = [
        {
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#ebe3cd',
                },
            ],
        },
        {
            'elementType': 'labels.text.fill',
            'stylers': [
                {
                    'color': '#523735',
                },
            ],
        },
        {
            'elementType': 'labels.text.stroke',
            'stylers': [
                {
                    'color': '#f5f1e6',
                },
            ],
        },
        {
            'featureType': 'administrative',
            'elementType': 'geometry',
            'stylers': [
                {
                    'visibility': 'off',
                },
            ],
        },
        {
            'featureType': 'administrative',
            'elementType': 'geometry.stroke',
            'stylers': [
                {
                    'color': '#c9b2a6',
                },
            ],
        },
        {
            'featureType': 'administrative.land_parcel',
            'elementType': 'geometry.stroke',
            'stylers': [
                {
                    'color': '#dcd2be',
                },
            ],
        },
        {
            'featureType': 'administrative.land_parcel',
            'elementType': 'labels',
            'stylers': [
                {
                    'visibility': 'off',
                },
            ],
        },
        {
            'featureType': 'administrative.land_parcel',
            'elementType': 'labels.text.fill',
            'stylers': [
                {
                    'color': '#ae9e90',
                },
            ],
        },
        {
            'featureType': 'landscape.man_made',
            'stylers': [
                {
                    'visibility': 'off',
                },
            ],
        },
        {
            'featureType': 'landscape.natural',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#ffda99',
                },
            ],
        },
        {
            'featureType': 'poi',
            'stylers': [
                {
                    'visibility': 'off',
                },
            ],
        },
        {
            'featureType': 'road',
            'stylers': [
                {
                    'visibility': 'off',
                },
            ],
        },
        {
            'featureType': 'road',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#f5f1e6',
                },
            ],
        },
        {
            'featureType': 'road',
            'elementType': 'labels.icon',
            'stylers': [
                {
                    'visibility': 'off',
                },
            ],
        },
        {
            'featureType': 'road.arterial',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#fdfcf8',
                },
            ],
        },
        {
            'featureType': 'road.highway',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#f8c967',
                },
            ],
        },
        {
            'featureType': 'road.highway',
            'elementType': 'geometry.stroke',
            'stylers': [
                {
                    'color': '#e9bc62',
                },
            ],
        },
        {
            'featureType': 'road.highway.controlled_access',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#e98d58',
                },
            ],
        },
        {
            'featureType': 'road.highway.controlled_access',
            'elementType': 'geometry.stroke',
            'stylers': [
                {
                    'color': '#db8555',
                },
            ],
        },
        {
            'featureType': 'road.local',
            'elementType': 'labels',
            'stylers': [
                {
                    'visibility': 'off',
                },
            ],
        },
        {
            'featureType': 'road.local',
            'elementType': 'labels.text.fill',
            'stylers': [
                {
                    'color': '#806b63',
                },
            ],
        },
        {
            'featureType': 'transit',
            'stylers': [
                {
                    'visibility': 'off',
                },
            ],
        },
        {
            'featureType': 'transit.line',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#dfd2ae',
                },
            ],
        },
        {
            'featureType': 'transit.line',
            'elementType': 'labels.text.fill',
            'stylers': [
                {
                    'color': '#8f7d77',
                },
            ],
        },
        {
            'featureType': 'transit.line',
            'elementType': 'labels.text.stroke',
            'stylers': [
                {
                    'color': '#ebe3cd',
                },
            ],
        },
        {
            'featureType': 'transit.station',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#dfd2ae',
                },
            ],
        },
        {
            'featureType': 'water',
            'elementType': 'geometry.fill',
            'stylers': [
                {
                    'color': '#c7f7ff',
                },
            ],
        },
        {
            'featureType': 'water',
            'elementType': 'labels.text.fill',
            'stylers': [
                {
                    'color': '#92998d',
                },
            ],
        },
    ];

    @query('#map')
    mapElement: HTMLElement;

    @query('#innerContent')
    innerContentElement: HTMLElement;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                position: relative;
                height: 400px;
                background-color: rgb(229, 227, 223);
            }

            #map {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }

            .clusterMarker img {
                width: 100%;
            }
        `,
    ];

    override render() {
        return html`
            <div id="map">
            </div>

            <div id="innerContent">
                <slot></slot>
            </div>
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        this.isAttached = true;

        this.initMap();
    }

    @listen('fullscreenchange', document)
    onFullscreenChanged() {
        let innerContentElement = this.innerContentElement;
        let isFullscreen = document.fullscreenElement !== null;

        if (isFullscreen) {
            this.mapElement.children[0].appendChild(innerContentElement);

        } else {
            this.shadowRoot?.appendChild(innerContentElement);
        }
    }


    disconnectedCallback() {
        super.disconnectedCallback();

        this.isAttached = false;
    }

    async initMap() {
        if (this.map) return; // already initialized

        this.api = await getMaps(config.google.maps.key);

        if (!this.isAttached) return; // not attached


        this.map = new this.api.Map(this.mapElement, {
            center: {
                lat: this.center[0],
                lng: this.center[1],
            },
            zoom: this.zoom,
            styles: this.mapStyles,
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: true,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: true,
            minZoom: this.minZoom,
            maxZoom: this.maxZoom,
            restriction: {
                latLngBounds: {
                    north: 61,
                    south: 49,
                    west: -11,
                    east: 3,
                },
                strictBounds: true,
            },
        });
        this.initListeners();
    }

    updateCenter() {
        if (!this.map) return;

        this.map.setCenter(new this.api.LatLng(this.center[0], this.center[1]));
    }

    initListeners() {
        let mapsEvent = this.api.event;
        let map = this.map;
        mapsEvent.addListener(map, 'center_changed', () => {
            let center = map.getCenter();
            this.center = [center.lat(), center.lng()];
        });
        mapsEvent.addListener(map, 'zoom_changed', () => {
            this.zoom = map.getZoom();
        });
        mapsEvent.addListener(map, 'maptypeid_changed', () => {
            this.mapType = map.getMapTypeId();
        });
    }

    @observe('markers', 'map', 'api')
    loadMarkers(markers: any[], map: any, api: any) {
        if (!markers || !map || !api) return;

        for (let marker of markers) {
            let markerIcon = marker.icon;
            if (markerIcon?.scaledSize && Array.isArray(markerIcon?.scaledSize)) {
                markerIcon.scaledSize = new api.Size(...markerIcon.scaledSize);
            }
            if (markerIcon?.size && Array.isArray(markerIcon?.size)) {
                markerIcon.size = new api.Size(...markerIcon.size);
            }

            new api.Marker({
                map: map,
                ...marker,
            });
        }
    }

    @observe('dynamicCentering')
    async initDynamicCentering(dynamicCentering: boolean) {
        if (!dynamicCentering) return;

        let loadCenter = () => {
            this.center = (window as any).__browserInfo.cityLatLong.split(',').map((_: string) => parseFloat(_));
            this.updateCenter();
        };

        if ((window as any).__browserInfo) {
            loadCenter();
            return;
        }

        window.addEventListener('browserInfoLoaded', loadCenter);

        await loadTrackingLibrary('https://us-central1-lupi-browser-info.cloudfunctions.net/browserInfo?globalEvent=browserInfoLoaded&globalVariable=__browserInfo');
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-google-map': ComponentGoogleMap;
    }
}